<template>
  <cw-page
    icon="work"
    class="cw-sme-pos-applicant-start"
  >
    <template #hero-title>
      <translate>
        Welcome to Saldo Corporate loan application!
      </translate>
    </template>
    <validation-observer
      ref="observer"
      tag="div"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div
            class="
              cw-border-container
              cw-border-container--primary
            "
          >
            <p>
              <translate>
                A personal guarantee is required for a SME loan. The executor of the loan
                application acts as a guarantor and is responsible for managing the loan.
              </translate>
              <a
                id="cw-sme-pos-applicant-applicant--info"
                class="ml-1"
                href="#"
                @click.prevent.stop="
                  $bus.$emit('OPEN_CONFIRMATION_DIALOG', {
                    component: 'DialogGuarantor',
                    folder: 'fi',
                  });
                  $eventLogger.clickEvent($event);
                "
              >
                <translate>More information</translate>
              </a>
            </p>

            <p>
              <translate>
                Prepare to identify yourself with online banking ID or the Mobile ID.
                After a positive loan decision the granted amount is paid into the merchant’s
                account.
              </translate>
            </p>
          </div>

          <div class="cw-application__table mt-6">
            <table>
              <tbody>
                <tr v-for="row in tableData" :key="row.id">
                  <td>{{ row.label }}</td>
                  <td class="pl-0 text-right">
                    <b>{{ row.value }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <cw-checkbox
            id="isWarrantor"
            v-model="form.isWarrantor"
            rules="required"
          >
            <template #label>
              <span data-test>
                {{ isWarrantorLabel }}
              </span>
            </template>
          </cw-checkbox>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-alert
            border="left"
            class="confirmation__terms mb-0"
            color="primary"
            text
          >
            <v-row>
              <v-col>
                <h3 class="text-subtitle-1 font-weight-bold mb-1">
                  <translate>
                    Read and accept the Privacy Policy
                  </translate>
                </h3>
                <p class="grey--text text--darken-2">
                  {{ infoText }}
                </p>
                <cw-checkbox
                  id="dataHandlingPermission"
                  v-model="form.dataHandlingPermission"
                  class="my-4"
                  rules="required"
                  hide-details
                  prevent-set-data
                >
                  <template #label>
                    <span class="mr-3" data-test>
                      <translate>I accept the Privacy Policy</translate>
                    </span>
                    <a
                      v-if="isBrokerFlow()"
                      id="dataHandlingPermission--link"
                      target="_blank"
                      href="/pdf/Tact-Finance-Broker-Tietosuojaseloste.pdf"
                      @click.stop="$eventLogger.clickEvent($event);"
                    >
                      <translate>More information</translate>
                    </a>
                    <a
                      v-else
                      id="dataHandlingPermission--link"
                      target="_blank"
                      href="/pdf/privacy-policy-fi.pdf"
                      @click.stop="$eventLogger.clickEvent($event);"
                    >
                      <translate>More information</translate>
                    </a>
                  </template>
                </cw-checkbox>
                <cw-checkbox
                  id="dataGatheringPermission"
                  v-model="form.dataGatheringPermission"
                  class="mt-4"
                  rules="required"
                  hide-details
                  prevent-set-data
                >
                  <template #label>
                    <span class="mr-3" data-test>
                      <translate>I agree to reviewing customer information</translate>
                    </span>
                    <a
                      id="dataGatheringPermission--link"
                      href="#"
                      @click.prevent.stop="
                        $bus.$emit('OPEN_CONFIRMATION_DIALOG', {
                          component: 'DialogDataGatheringPermission',
                          folder: 'fi',
                        });
                        $eventLogger.clickEvent($event);
                      "
                    >
                      <translate>More information</translate>
                    </a>
                  </template>
                </cw-checkbox>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </validation-observer>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          validateForm();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import { goToError } from '@shared/mixins';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwSmePosApplicantStart',

  components: {
    'cw-checkbox': () => import('@shared/components/inputs/CwCheckbox'),
    'validation-observer': ValidationObserver,
  },

  mixins: [goToError],

  data: () => ({
    additionalData: {
      appliedLoanLimit: 0,
      appliedInstalmentCount: 0,
    },
    form: {
      dataGatheringPermission: 'off',
      dataHandlingPermission: 'off',
      isWarrantor: 'off',
    },
  }),

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      locale: 'application/locale',
    }),

    isWarrantorLabel: vm => vm.$gettext('I agree to act as guarantor of the loan'),
    infoText: vm => vm.$gettext('The loan application requires approval of the Privacy Policy and Customer Data Review. Once both are approved, you can continue to authenticate.'),

    tableData() {
      return [
        {
          id: 1,
          label: this.$gettext('Company'),
          value: `${this.initialData.companyName}, ${this.initialData.businessId}`,
        },
        {
          id: 2,
          label: this.$gettext('Product to be purchased'),
          value: this.initialData.smeProductName,
        },
        {
          id: 3,
          label: this.$gettext('Applied loan amount'),
          value: this.$filters.currency(this.additionalData.appliedLoanLimit, {
            locale: this.locale,
          }),
        },
        {
          id: 4,
          label: this.$gettext('Applied loan period'),
          value: this.getLoanPeriod(this.additionalData.appliedInstalmentCount),
        },
      ];
    },
  },

  watch: {
    initialData: {
      handler(data) {
        this.additionalData.appliedLoanLimit = parseInt(data.appliedLoanLimit, 10) || 0;
        this.additionalData.appliedInstalmentCount = parseInt(data.appliedInstalmentCount, 10)
          || 0;
      },
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    this.$dataLayer.PushToECommerce({
      params: {
        action: 'businessLoanDetail',
        formData: { ...this.form, ...this.additionalData },
      },
    });
  },

  methods: {
    ...mapActions({
      submitForm: 'application/submit',
    }),
    ...mapGetters({
      isBrokered: 'application/isBrokered',
    }),

    isBrokerFlow() {
      return this.isBrokered();
    },

    getLoanPeriod(n) {
      return this.$gettextInterpolate(this.$ngettext(
        '%{ n } mo',
        '%{ n } mos',
        n,
      ), { n });
    },

    async validateForm() {
      const valid = await this.$refs.observer.validate();

      this.$dataLayer.PushToECommerce({
        params: {
          action: 'b2bStepValidationClick',
          formData: { ...this.form, ...this.additionalData },
        },
      });

      DataLayer.onValidationClick();

      if (!valid) {
        this.goToError();
        DataLayer.onValidationFail();

        return;
      }

      this.$dataLayer.PushToECommerce({
        params: {
          action: 'b2bStepValidationSubmit',
          formData: { ...this.form, ...this.additionalData },
        },
      });

      await this.submit();
    },

    async submit() {
      this.$wait.start('SUBMIT_FORM');

      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Submit Applicant Start',
      };

      try {
        this.$dataLayer.PushToECommerce({
          params: {
            action: 'addToCartB2b',
            formData: { ...this.form, ...this.additionalData },
          },
        });

        await this.submitForm({
          formParams: this.form,
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>

<style lang="scss">
.underline {
  text-decoration: underline;
}
</style>
